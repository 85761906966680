var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      _c(
        "v-btn",
        {
          staticClass: "my-3",
          attrs: { color: "primary" },
          on: { click: _vm.closeDetail },
        },
        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
        1
      ),
      _c(
        "v-card",
        { attrs: { color: "secondary" } },
        [
          _c("div", { staticClass: "text-h3 pa-5 white--text" }, [
            _vm._v(" Subscriptions "),
          ]),
          _c(
            "v-list",
            { attrs: { color: "" } },
            [
              this.api.isLoading
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "card-avatar" },
                  })
                : _vm._e(),
              _vm._l(_vm.subscription, function (item, index) {
                return _c(
                  "v-list-item",
                  { key: index },
                  [
                    _c(
                      "v-container",
                      [
                        _c("v-card", [
                          _c("div", { staticClass: "pa-4" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start text-h6" },
                              [_vm._v(" " + _vm._s(item.plan_name) + " ")]
                            ),
                            item.price != null
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("p", [
                                      _vm._v(" RM " + _vm._s(item.price) + " "),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            item.coupon
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start " },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-decoration-line-through",
                                      },
                                      [
                                        _vm._v(
                                          " RM " + _vm._s(item.price) + " "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "ml-2" }, [
                                      _vm._v(" (" + _vm._s(item.coupon) + ") "),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start " },
                              [
                                _c("p", {}, [_vm._v(" Status : ")]),
                                _c(
                                  "div",
                                  { staticClass: "ml-2 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.stripe_status) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            item.total_discount != null &&
                            item.total_discount != "0"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _vm._v(" Discount : "),
                                    _c(
                                      "p",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " RM " +
                                            _vm._s(
                                              item.total_discount.substring(
                                                0,
                                                item.total_discount.length - 2
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "d-flex justify-start" }, [
                              _vm._v(" Price : "),
                              item.price != null && item.price != "0"
                                ? _c("p", { staticClass: "font-weight-bold" }, [
                                    _vm._v(" RM " + _vm._s(item.price) + " "),
                                  ])
                                : _c("p", { staticClass: "font-weight-bold" }, [
                                    _vm._v(" RM 0 "),
                                  ]),
                            ]),
                            _c("hr"),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "d-flex justify-start font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.verification) +
                                    " ( " +
                                    _vm._s(
                                      _vm.convertTimeZone(item.created_at)
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.convertTimeZone(item.renewal_at)
                                    ) +
                                    ") "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }